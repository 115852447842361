export default {
  methods: {
    cleanEmail(email) {
      email = email.toLowerCase();
      email = email.replace(/\s/g, '');
      return email;
    },
    scrollToErrorSection($el) {
      // Scroll to the first element that has 'has-error' class.
      let elements = $el.getElementsByClassName('has-error');
      if (elements && elements.length > 0) {
        let el = $el.getElementsByClassName('has-error')[0];
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
    getRandomInt(min, max) {
      const minCeil = Math.ceil(min);
      const maxFloor = Math.floor(max);
      return Math.floor(Math.random() * (maxFloor - minCeil + 1) + minCeil);
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
    updateUrlQueryNoRefresh(params, $route) {
      history.pushState(
        {},
        null,
        $route.path +
          '?' +
          Object.keys(params)
            .map((key) => {
              return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
            })
            .join('&'),
      );
    },
    stripHtml(htmlText) {
      // Error document not found
      // let element = document.createElement("div");
      // element.innerHtml = htmlText;
      // return element.innerText;

      if (htmlText) {
        let regex = /(<([^>]+)>)/gi;
        return htmlText.replace(regex, '');
      }
      return htmlText;
    },
    validUrl(str) {
      var pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i',
      ); // fragment locator
      return !!pattern.test(str);
    },
    getPriceAfterDiscount(discountPercentage, discountPrice, price) {
      price = parseInt(price);
      let result = {
        discount_value: null,
        price_after_discount: price,
      };

      if (discountPercentage) {
        discountPercentage = parseFloat(discountPercentage);
        let discountValue = (price * discountPercentage) / 100;
        result.discount_value = discountValue;
        result.price_after_discount = price - discountValue;
      } else if (discountPrice) {
        discountPrice = parseFloat(discountPrice);
        let discountValue = parseInt(discountPrice);
        result.discount_value = discountValue;
        result.price_after_discount = price - discountValue;
      }
      return result;
    },
    getListingJsonld({ baseUrl, path, listing, galleries }) {
      if (!listing) return {};
      let bedroom = null;
      let bathroom = null;
      if (listing.facilities) {
        let bedroomTemp = listing.facilities.find((element) => element.attribute_id === 29);
        if (bedroomTemp) {
          bedroom = bedroomTemp.amount;
        }
        let bathroomTemp = listing.facilities.find((element) => element.attribute_id === 10);
        if (bathroomTemp) {
          bathroom = bathroomTemp.amount;
        }
      }
      let floorSize = 0;
      if (listing.size_in_square_meters) {
        floorSize = listing.size_in_square_meters;
      }
      let lat = null,
        lng = null;
      if (listing.property.latlng) {
        lat = listing.property.latlng.lat;
        lng = listing.property.latlng.lng;
      }
      let price = 0;
      if (listing.rent_prices) {
        for (let tempPrice of listing.rent_prices) {
          price = tempPrice.price;
          break;
        }
      }
      if (listing.sell_price) {
        price = listing.sell_price;
      }
      let images = [];
      for (let gallery of galleries) {
        images.push(gallery.url);
      }
      let streetAddress = '';
      if (listing.property.address_line_1) {
        streetAddress += listing.property.address_line_1;
      }
      if (listing.property.address_line_2) {
        if (streetAddress) {
          streetAddress += ', ';
        }
        streetAddress += listing.property.address_line_2;
      }
      return {
        '@context': 'https://schema.org',
        '@type': ['Product', 'Accommodation'],
        accommodationCategory: listing.property.property_type_name,
        description: listing.listing_title + '. ' + listing.property.about_property,
        name: listing.listing_title,
        url: baseUrl + path,
        numberOfBedrooms: bedroom,
        numberOfBathroomsTotal: bathroom,
        floorSize: {
          '@type': 'QuantitativeValue',
          unitCode: 'MTK',
          value: floorSize,
        },
        address: {
          '@type': 'PostalAddress',
          addressLocality: `${listing.property.location.district_name}, ${listing.property.location.city_name}`,
          addressRegion: listing.property.location.province_name
            ? listing.property.location.province_name
            : '',
          streetAddress: streetAddress,
          addressCountry: {
            '@type': 'Country',
            name: 'ID',
          },
        },
        geo: {
          '@type': 'GeoCoordinates',
          latitude: lat,
          longitude: lng,
        },
        image: images,
        offers: {
          '@type': 'Offer',
          availability: listing.isActive
            ? 'http://schema.org/InStock'
            : 'https://schema.org/OutOfStock',
          price: price,
          priceCurrency: 'IDR',
          url: baseUrl + path,
          offeredBy: {
            '@type': 'Brand',
            name: listing.owner ? listing.owner.fullname : 'Rentfix',
          },
        },
      };
    },
    getAreaJsonld({ baseUrl, path, area, galleries }) {
      return {
        '@context': 'https://schema.org',
        '@type': ['Product', 'Place'],
        description: area.name,
        name: area.name,
        url: baseUrl + path,
        address: {
          '@type': 'PostalAddress',
          addressLocality: area.region,
          addressCountry: {
            '@type': 'Country',
            name: 'ID',
          },
        },
        image: galleries,
        offers: {
          '@type': 'Offer',
          availability: 'http://schema.org/InStock',
          price: area.min_price,
          priceCurrency: 'IDR',
          url: baseUrl + path,
          offeredBy: {
            '@type': 'Brand',
            name: area.partner_name ? area.partner_name : 'Rentfix',
          },
        },
      };
    },
    getFallbackImage(url) {
      let newUrl = '/img/placeholder.png';
      if (url) {
        newUrl = url.replace('.webp', '');
      }
      return newUrl;
    },
  },
};
