<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <section class="container transaction--container">
      <div class="row">
        <div class="col-md-8">
          <page-loading :show="loading" />
          <listing-info :listing="bookingInfo.listing" v-if="bookingInfo" />
          <billing-information-rent
            v-if="bookingInfo && bookingInfo.listing.type == 'R'"
            ref="billingInfo"
          />
          <payment-method />
        </div>
        <div class="col-md-4">
          <checkout-info
            page-type="edit-booking"
            ref="booking"
            @submit="submit()"
            :bookingInfo="bookingInfo"
            v-if="bookingInfo"
          />
        </div>
        <div class="col-md-8"></div>
      </div>
      <modal-check-booking />
      <modal-midtrans-overlay />
    </section>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import ListingInfo from '@/components/utils/listing-info';
const CheckoutInfo = () => import('@/components/checkout/checkout-info');
const PageLoading = () => import('@/components/content-loading/page-loading');
const BillingInformationRent = () => import('@/components/checkout/billing-information-rent');
const PaymentMethod = () => import('@/components/checkout/payment-method');
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';

const ModalCheckBooking = () =>
  import('@/components/application-action/modals/booking/check-booking-modal');
const ModalMidtransOverlay = () => import('@/components/checkout/modals/midtrans-overlay');
export default {
  name: 'pay-page',
  head: {
    script: [
      {
        src:
          process.env.NODE_ENV !== 'production'
            ? 'https://app.sandbox.midtrans.com/snap/snap.js'
            : 'https://app.midtrans.com/snap/snap.js',
        'data-client-key':
          process.env.NODE_ENV !== 'production'
            ? 'SB-Mid-client-CuD6mtMVghH4hr1N'
            : 'VT-client-oCm1a2S_OkSuQ5NL',
      },
    ],
  },
  mixins: [HelperMixin],
  components: {
    DefaultLayout,
    CheckoutInfo,
    PageLoading,
    PaymentMethod,
    BillingInformationRent,
    ModalCheckBooking,
    ListingInfo,
    ModalMidtransOverlay,
  },
  computed: {
    ...mapState({
      loading: (state) => state.checkout.pay.loading,
      bookingInfo: (state) => state.checkout.pay.bookingInfo,
      billingInfo: (state) => state.checkout.billingInfo,
      paymentMethod: (state) => state.checkout.pay.paymentMethod,
    }),
    breadcrumbs() {
      if (!this.bookingInfo) return [];
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: this.$t('home.sidebarMenu.mybooking'),
          to: '/dashboard/mybooking',
        },
        {
          text: `${this.$t('booking.number')} ${
            this.bookingInfo.order_number ? this.bookingInfo.order_number : this.uuid
          }`,
          to: `/mybooking/detail?type=${this.$route.query.type}&uuid=${this.$route.query.uuid}`,
        },
        {
          text: this.$t('breadcrumb.payment'),
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
  async fetch({ store, params, router, route }) {
    console.log('params', params);
    store.commit('head/RESTORE_DEFAULT_VALUE', route);
    await store.dispatch('checkout/pay/restoreInitialState');
    await store.dispatch('checkout/billingInfo/restoreInitialState');
    store.commit('checkout/pay/SET_ORDER_NUMBER', params.orderNumber);
    const allowed = await store.dispatch('checkout/pay/checkAccessAllowed');
    if (!allowed) router.replace('/dashboard/mybooking');
    await store.dispatch('checkout/pay/getData');
  },
  methods: {
    async submit() {
      try {
        let valid = true;
        if (this.bookingInfo.listing.type === 'R') {
          valid = await this.$refs.billingInfo.validate();
        }
        if (valid) {
          const result = await this.$store.dispatch('checkout/pay/checkPaymentAllowed');
          const allowBook = result.is_allowed;
          const warningBook = result.warning_message;
          console.log(allowBook);
          if (!allowBook) {
            this.$modal.show('modal--check-booking', {
              message: warningBook,
            });
          } else {
            let billingInfoRequest = null;
            if (this.bookingInfo.listing.type === 'R') {
              billingInfoRequest = {
                address: this.billingInfo.address,
                full_name: this.billingInfo.fullName,
                ktp: this.billingInfo.ktp,
                phone: this.billingInfo.phone,
                city: this.billingInfo.city,
              };
              // console.log('asddd',billingInfoRequest);
            }

            const data = await this.$store.dispatch('checkout/pay/submit', billingInfoRequest);
            if (this.paymentMethod === 'bniva') {
              if (data.type === 'success') {
                this.$router.replace(
                  `/booking/how-to-pay/${this.$route.query.uuid}?type=${this.$route.query.type}`,
                );
              } else {
                this.$swal(this.$i18n.t('booking.failedPayment'), data.message, 'error');
              }
            }
            if (this.paymentMethod === 'gopay') {
              if (data.type === 'success') {
                this.$router.replace(
                  `/booking/how-to-pay/${this.$route.query.uuid}?type=${this.$route.query.type}`,
                );
              } else {
                this.$swal(this.$i18n.t('booking.failedPayment'), data.message, 'error');
              }
            }

            if (this.paymentMethod === 'permatava' || this.paymentMethod === 'credit_card') {
              console.log('Open midtrans: ', data);
              let self = this;
              self.$modal.show('modal-midtrans-overlay');
              // eslint-disable-next-line no-undef
              snap.pay(data, {
                // eslint-disable-next-line no-unused-vars
                onSuccess: (result) => {
                  self.$modal.hide('modal-midtrans-overlay');
                  // self.$router.push('/dashboard/mybooking');
                  this.$router.replace(
                    `/mybooking/detail?type=${this.$route.query.type}&uuid=${this.$route.query.uuid}`,
                  );
                },
                // eslint-disable-next-line no-unused-vars
                onPending: (result) => {
                  self.$modal.hide('modal-midtrans-overlay');
                  this.$router.replace(
                    `/booking/how-to-pay/${this.$route.query.uuid}?type=${this.$route.query.type}`,
                  );
                },
                onError: (result) => {
                  self.$modal.hide('modal-midtrans-overlay');
                  this.$store.commit('global/SET_ALERT_MESSAGE', {
                    message: result,
                    messageType: 'danger',
                  });
                  this.messageShow = true;
                },
              });
            }
          }
        } else {
          console.log('anjay', this.$refs.billingInfo.$el);
          this.scrollToErrorSection(this.$refs.billingInfo.$el);
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
        // throw e;
        console.log('eradd', e);
      }
    },
  },
};
</script>

<style scoped></style>
